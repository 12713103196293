import React, { useEffect, useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';

const TagLineMobile = () => {
  const newContainer = useRef(null);
  const { scrollYProgress } = useScroll({
    target: newContainer,
    offset: ['start 0.7', 'end end'],
  });

  const line1 = useTransform(scrollYProgress, [0, 1], ['0vh', '15vh']);

  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      console.log('Scroll Progress:', latest);
    });
  }, [scrollYProgress]);

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        fontFamily: 'Fjalla One',
      }}
    >
      <div
        ref={newContainer}
        style={{
          position: 'relative',
          width: '100vw',
          height: '10vh',
          backgroundColor: 'black',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <motion.div
          style={{
            fontSize: '12vw',
            fontWeight: 'bold',
            textAlign: 'center',
            position: 'absolute',
            lineHeight: '12vh',
            width: '100%',
            top: line1,
            color: 'white',
          }}
        >
          CUSTOM
        </motion.div>
      </div>
      <div
        ref={newContainer}
        style={{
          position: 'relative',
          width: '100vw',
          height: '10vh',
          backgroundColor: 'black',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <motion.div
          style={{
            fontSize: '12vw',
            fontWeight: 'bold',
            textAlign: 'center',
            position: 'absolute',
            lineHeight: '12vh',
            width: '100%',
            top: line1,
            color: 'white',
          }}
        >
          SOLUTIONS
        </motion.div>
      </div>
      <div
        ref={newContainer}
        style={{
          position: 'relative',
          width: '100vw',
          height: '10vh',
          backgroundColor: 'black',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <motion.div
          style={{
            fontSize: '12vw',
            fontWeight: 'bold',
            textAlign: 'center',
            position: 'absolute',
            lineHeight: '12vh',
            width: '100%',
            top: line1,
            color: 'white',
          }}
        >
          FOR EVERY
        </motion.div>
      </div>
      <div
        ref={newContainer}
        style={{
          position: 'relative',
          width: '100vw',
          height: '10vh',
          backgroundColor: 'black',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <motion.div
          style={{
            fontSize: '12vw',
            fontWeight: 'bold',
            textAlign: 'center',
            position: 'absolute',
            lineHeight: '12vh',
            width: '100%',
            top: line1,
            color: 'white',
          }}
        >
          BUSINESS
        </motion.div>
      </div>
      {/* <div>TO ACHIEVE MORE,</div>
      <div>TOGETHER</div> */}
    </div>
  );
};

export default TagLineMobile;
