import { useScroll, useTransform, motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../lib/Colors';
import { SECONDARY_FONT } from '../../lib/Fonts';

const HowWeWorkMobile = () => {
  const anotherContainer = useRef(null);
  const { scrollYProgress } = useScroll({
    target: anotherContainer,
    offset: ['start end', 'end end'],
  });

  useEffect(() => {
    console.log(scrollYProgress.current);
  }, [scrollYProgress]);

  const opacity1 = useTransform(scrollYProgress, [0.1, 0.25], [0, 1]);
  const opacity2 = useTransform(scrollYProgress, [0.25, 0.5], [0, 1]);
  const opacity3 = useTransform(scrollYProgress, [0.5, 0.75], [0, 1]);
  const opacity4 = useTransform(scrollYProgress, [0.75, 0.95], [0, 1]);

  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      console.log('Scroll Progress:', latest);
    });
  }, [scrollYProgress]);

  return (
    <div
      ref={anotherContainer}
      style={{
        width: '100vw',
        height: '300vh',
        fontFamily: SECONDARY_FONT,
        backgroundColor: 'black',
        display: 'flex',
      }}
    >
      <motion.div
        style={{
          position: 'sticky',
          width: '100vw',
          height: '100vh',
          top: 0,
          backgroundColor: 'black',
          overflow: 'hidden',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '15px',
        }}
      >
        <div
          style={{
            width: '100%',
            top: '2vh',
            fontSize: '8vw',
            color: 'white',
            margin: '0vw 5vw',
            padding: '0vw 2vw',
            borderLeft: `5px solid ${PRIMARY_COLOR}`,
            position: 'absolute',
            textAlign: 'center',
            fontFamily: 'Fjalla One',
          }}
        >
          WHY AURETE?
        </div>
        <motion.div
          style={{
            width: '95vw',
            height: '20%',
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box', // Ensure padding is included in the element's total width and height
            top: '10vh',
            opacity: opacity1,
            position: 'absolute',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              fontSize: '8vw',
              color: '#63d6aa',
              margin: '0vw 5vw',
              fontFamily: 'Fjalla One',
              textTransform: 'uppercase',
            }}
          >
            Global Teams
          </div>
          <div
            style={{
              fontSize: '3vw',
              color: 'white ',
              margin: '0vw 5vw',
              fontFamily: SECONDARY_FONT,
            }}
          >
            We leverage a network of global talent, allowing us to assemble
            diverse teams that offer unique perspectives and expertise, ensuring
            the best solutions for your business.
          </div>
        </motion.div>

        <motion.div
          style={{
            width: '95vw',
            height: '20%',
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box', // Ensure padding is included in the element's total width and height
            top: '30vh',
            opacity: opacity2,
            position: 'absolute',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              fontSize: '8vw',
              color: '#63d6aa',
              margin: '0vw 5vw',
              fontFamily: 'Fjalla One',
              textTransform: 'uppercase',
            }}
          >
            Client-Specific Hiring
          </div>
          <div
            style={{
              fontSize: '3vw',
              color: 'white ',
              margin: '0vw 5vw',
              fontFamily: SECONDARY_FONT,
            }}
          >
            Our recruitment process is tailored to match the specific needs of
            each client, ensuring that we provide the right skills and
            experience for every project.
          </div>
        </motion.div>

        <motion.div
          style={{
            width: '95vw',
            height: '20%',
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box', // Ensure padding is included in the element's total width and height
            top: '50vh',
            opacity: opacity3,
            position: 'absolute',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              fontSize: '8vw',
              color: '#63d6aa',
              margin: '0vw 5vw',
              fontFamily: 'Fjalla One',
              textTransform: 'uppercase',
            }}
          >
            Verified Resources
          </div>
          <div
            style={{
              fontSize: '3vw',
              color: 'white ',
              margin: '0vw 5vw',
              fontFamily: SECONDARY_FONT,
            }}
          >
            We maintain stringent compliance and verification processes for all
            our resources, guaranteeing reliability and adherence to industry
            standards.
          </div>
        </motion.div>
        <motion.div
          style={{
            width: '95vw',
            height: '20%',
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'border-box', // Ensure padding is included in the element's total width and height
            top: '70vh',
            opacity: opacity4,
            position: 'absolute',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              fontSize: '8vw',
              color: '#63d6aa',
              margin: '0vw 5vw',
              fontFamily: 'Fjalla One',
              textTransform: 'uppercase',
            }}
          >
            Fully Equipped Resources
          </div>
          <div
            style={{
              fontSize: '3vw',
              color: 'white ',
              margin: '0vw 5vw',
              fontFamily: SECONDARY_FONT,
            }}
          >
            All team members are provided with state-of-the-art tools and
            resources by Aurete, ensuring they are fully prepared to deliver
            high-quality results.
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default HowWeWorkMobile;
