import React from 'react';
import { motion } from 'framer-motion';
import { SECONDARY_COLOR } from '../../lib/Colors';
import { SECONDARY_FONT } from '../../lib/Fonts';

const BackToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      style={{
        width: '100vw',
        height: '50vh',
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <motion.div
        onClick={scrollToTop}
        style={{
          fontFamily: SECONDARY_FONT,
          padding: '10px 20px',
          fontSize: '20px',
          fontWeight: 'bold',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: '50px',
        }}
        animate={{
          y: [0, -20, 0],
        }}
        transition={{
          duration: 1,
          repeat: Infinity,
          repeatType: 'loop',
        }}
      >
        <motion.div
          style={{
            width: 0,
            height: 0,
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderBottom: '15px solid white',
            marginBottom: '5px',
          }}
          animate={{
            y: [0, -5, 0],
          }}
          transition={{
            duration: 0.5,
            repeat: Infinity,
            repeatType: 'loop',
          }}
        />
        Back to top
      </motion.div>
    </div>
  );
};

export default BackToTop;
