import { useScroll, useTransform, motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../lib/Colors';
import { SECONDARY_FONT } from '../../lib/Fonts';

const HorizontalScrollMobile = () => {
  const anotherContainer = useRef(null);
  const { scrollYProgress } = useScroll({
    target: anotherContainer,
    offset: ['start end', 'end end'],
  });

  useEffect(() => {
    console.log(scrollYProgress.current);
  }, [scrollYProgress]);

  const opacity1 = useTransform(scrollYProgress, [0.25, 0.3], [0, 1]);
  const opacity2 = useTransform(scrollYProgress, [0.3, 0.6], [0, 1]);
  const opacity3 = useTransform(scrollYProgress, [0.6, 0.9], [0, 1]);

  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      console.log('Scroll Progress:', latest);
    });
  }, [scrollYProgress]);

  return (
    <div
      ref={anotherContainer}
      style={{
        width: '100vw',
        height: '300vh',
        fontFamily: SECONDARY_FONT,
        backgroundColor: 'black',
        display: 'flex',
      }}
    >
      <div
        style={{
          position: 'sticky',
          width: '100vw',
          height: '100vh',
          top: 0,
          backgroundColor: 'black',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '15px',
        }}
      >
        <motion.div
          style={{
            width: '90vw',
            height: '30%',
            border: `3px solid ${PRIMARY_COLOR}`,
            display: 'flex',
            flexDirection: 'column',
            padding: '20px', // Add padding around the border
            boxSizing: 'border-box', // Ensure padding is included in the element's total width and height
            opacity: opacity1,
            top: '2vh',
            position: 'absolute',
          }}
        >
          <div
            style={{
              fontSize: '10vw',
              color: PRIMARY_COLOR,
              fontFamily: 'Playfair Display, serif',
              marginBottom: 'auto', // Push the "1" to the top
              flex: 1,
            }}
          >
            1
          </div>
          <div
            style={{
              alignSelf: 'flex-end', // Align text to the bottom
              display: 'flex',
              flexDirection: 'column',
              gap: '1vw',
              flex: 2,
            }}
          >
            <div
              style={{
                fontSize: '6vw',
                color: SECONDARY_COLOR,
                fontFamily: 'Fjalla One',
                textTransform: 'uppercase',
                flex: 1,
              }}
            >
              Start ups
            </div>
            <div
              style={{
                fontSize: '3vw',
                color: 'white',
                fontFamily: SECONDARY_FONT,
                flex: 1,
              }}
            >
              Starting a business and securing funding is hard. Aurete supports
              you from planning to launch and upgrades, turning your ideas into
              reality.
            </div>
          </div>
        </motion.div>

        <motion.div
          style={{
            width: '90vw',
            height: '30%',
            border: `3px solid ${PRIMARY_COLOR}`,
            display: 'flex',
            flexDirection: 'column',
            padding: '20px', // Add padding around the border
            boxSizing: 'border-box', // Ensure padding is included in the element's total width and height
            opacity: opacity2,
            top: '35vh',
            position: 'absolute',
          }}
        >
          <div
            style={{
              fontSize: '10vw',
              color: PRIMARY_COLOR,
              fontFamily: 'Playfair Display, serif',
              marginBottom: 'auto', // Push the "1" to the top
              flex: 1,
            }}
          >
            2
          </div>
          <div
            style={{
              alignSelf: 'flex-end', // Align text to the bottom
              display: 'flex',
              flexDirection: 'column',
              gap: '1vw',
              flex: 2,
            }}
          >
            <div
              style={{
                fontSize: '6vw',
                color: SECONDARY_COLOR,
                fontFamily: 'Fjalla One',
                textTransform: 'uppercase',
                flex: 1,
              }}
            >
              Small medium businesses
            </div>
            <div
              style={{
                fontSize: '3vw',
                color: 'white',
                fontFamily: SECONDARY_FONT,
                flex: 1,
              }}
            >
              At Aurete, we believe in building long-term partnerships. To
              support eligible SMBs, we offer a 45-day credit cycle to start
              with us, requiring no upfront cost.
            </div>
          </div>
        </motion.div>
        <motion.div
          style={{
            width: '90vw',
            height: '30%',
            border: `3px solid ${PRIMARY_COLOR}`,
            display: 'flex',
            flexDirection: 'column',
            padding: '20px', // Add padding around the border
            boxSizing: 'border-box', // Ensure padding is included in the element's total width and height
            opacity: opacity3,
            top: '68vh',
            position: 'absolute',
          }}
        >
          <div
            style={{
              fontSize: '10vw',
              color: PRIMARY_COLOR,
              fontFamily: 'Playfair Display, serif',
              marginBottom: 'auto', // Push the "1" to the top
              flex: 1,
            }}
          >
            3
          </div>
          <div
            style={{
              alignSelf: 'flex-end', // Align text to the bottom
              display: 'flex',
              flexDirection: 'column',
              gap: '1vw',
              flex: 2,
            }}
          >
            <div
              style={{
                fontSize: '6vw',
                color: SECONDARY_COLOR,
                fontFamily: 'Fjalla One',
                textTransform: 'uppercase',
                flex: 1,
              }}
            >
              Enterprise
            </div>
            <div
              style={{
                fontSize: '3vw',
                color: 'white',
                fontFamily: SECONDARY_FONT,
                flex: 1,
              }}
            >
              Aurete can seamlessly white label as part of your team to ensure
              timely delivery and provide a full onshore team with security
              clearance if needed.
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default HorizontalScrollMobile;
