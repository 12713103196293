import React from 'react';
import { motion } from 'framer-motion';
import logo from '../images/logo_aurete.png';

const Loading = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <motion.img
        src={logo}
        alt="AURETE"
        style={{
          width: '100%', // Adjust the size of the image as needed
          height: 'auto', // Maintain the aspect ratio
          transition: 'all 1.5s',
        }}
        initial={{ scale: 1, opacity: 1 }}
        animate={{ scale: 0.5, opacity: 0 }}
        transition={{ duration: 0 }}
      />
    </div>
  );
};

export default Loading;
