/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Navbar from '../Navbar/NavbarMobile';
import HeroAnimation from '../HeroAnimationMobile';
import { SECONDARY_COLOR } from '../../lib/Colors';

const texts = ['Remote  Team', 'HR  Operation', 'Compliances'];

const HomeMobile = ({ toggleMenu }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const rotateXVariants = {
    initial: { opacity: 0, rotateX: 90 },
    enter: { opacity: 1, rotateX: 0 },
    exit: { opacity: 0, rotateX: -90 },
  };

  const splitTextIntoWords = (text) => {
    return text.split(' ').map((word, index) => (
      <motion.span
        key={index}
        variants={rotateXVariants}
        initial="initial"
        animate="enter"
        exit="exit"
        transition={{ duration: 1, delay: index * 0.2 }}
        style={{ display: 'inline-block', margin: '0 5px' }}
      >
        {word}
      </motion.span>
    ));
  };

  return (
    <div>
      <motion.div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'black',
          position: 'absolute',
          transition: 'all 1.5s ease 1s',
        }}
        initial={{ y: 0 }}
        animate={{ y: '-100vh' }}
      >
        <Navbar toggleMenu={toggleMenu} />
        <div
          style={{
            width: '100%',
            height: '90%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <motion.div
            style={{
              color: 'white',
              fontFamily: 'Fjalla One',
              fontSize: '12vw',
              fontWeight: 'bold',
              textAlign: 'center',
              width: '90%',
              position: 'absolute',
              zIndex: 4,
              textShadow: '2px 2px 5px rgba(0, 0, 0, 1)',
              textTransform: 'uppercase',
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 3, delay: 2.5 }}
          >
            <div style={{ fontSize: '6.9vw' }}>Cost of developers</div>
            <div style={{ fontSize: '6.8vw' }}>slowing progress?</div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ fontSize: '14vw' }}>Build</div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '0.5vw',
                    }}
                  >
                    <div style={{ fontSize: '5.7vw' }}>with</div>
                    <div style={{ fontSize: '5.7vw', color: SECONDARY_COLOR }}>
                      highly
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '0.5vw',
                    }}
                  >
                    <div style={{ fontSize: '4vw', color: SECONDARY_COLOR }}>
                      vetted
                    </div>
                    <div style={{ fontSize: '4vw' }}>offshore</div>
                  </div>
                </div>
              </div>
              <div style={{ fontSize: '6.25vw' }}>Software developers</div>
            </div>
            {/* <motion.div
              key={currentTextIndex}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
            >
              {splitTextIntoWords(texts[currentTextIndex])}
            </motion.div> */}
          </motion.div>
          <HeroAnimation />
        </div>
      </motion.div>
    </div>
  );
};

export default HomeMobile;
