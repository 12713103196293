import React from 'react';
import { motion } from 'framer-motion';
import logo from '../../images/logo_aurete.png';
import { PRIMARY_FONT } from '../../lib/Fonts';
import { SECONDARY_COLOR } from '../../lib/Colors';

const Navbar = ({ toggleMenu }) => {
  return (
    <div
      style={{
        width: 'calc(100% - 60px)',
        margin: '20px 30px',
        height: '10%',
        color: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontFamily: 'Fjalla One',
        fontWeight: 'bold',
      }}
    >
      <motion.img
        src={logo}
        alt="AURETE"
        style={{
          display: 'inline-block',
          width: 'auto', // Adjust as necessary
          height: '100%', // Adjust as necessary
          backgroundSize: '400% 400%', // Apply similar gradient effect if needed
          overflow: 'hidden',
        }}
        initial={{ y: '-10vh', opacity: 0 }}
        animate={{
          y: 0,
          opacity: 1,
        }}
        transition={{
          duration: 1.5,
          ease: 'easeInOut',
          delay: 2.5,
        }}
      />
      <motion.div
        style={{
          fontSize: '12px',
          fontFamily: PRIMARY_FONT,
          fontWeight: '100',
          cursor: 'pointer',
          padding: '6px 8px',
          borderRadius: '3px',
          border: `1px solid ${SECONDARY_COLOR}`,
        }}
        initial={{ y: '-10vh', opacity: 0 }}
        animate={{
          y: 0,
          opacity: 1,
        }}
        transition={{
          duration: 1.5, // Duration of one complete cycle
          ease: 'easeInOut',
          delay: 2.5,
        }}
        whileTap={{ scale: 0.9 }}
        onClick={() => toggleMenu()}
      >
        CONTACT US
      </motion.div>
    </div>
  );
};

export default Navbar;
