import React, { useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../lib/Colors';
import { SECONDARY_FONT } from '../../lib/Fonts';

const Intro = () => {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start end', '0.5 0.5'],
  });

  const borderBottom = useTransform(scrollYProgress, [0, 1], ['0%', '50%']);
  const textBottom = useTransform(scrollYProgress, [0, 1], ['0%', '50%']);

  return (
    <motion.div
      ref={container}
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontFamily: 'Fjalla One',
        backgroundColor: 'red',
        flexDirection: 'row',
      }}
    >
      <div
        style={{
          width: '50vw',
          height: '100vh',
          backgroundColor: 'black',
          fontSize: '5vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'Fjalla One',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <span style={{ color: SECONDARY_COLOR }}>AU</span>
            <span>STRALIAN</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <span style={{ color: SECONDARY_COLOR }}>RE</span>
            <span>MOTE</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <span style={{ color: SECONDARY_COLOR }}>TE</span>
            <span>AMS</span>
          </div>
        </div>
        <motion.div
          style={{
            height: '25vw',
            width: '50%',
            borderLeft: `5px solid ${PRIMARY_COLOR}`,
            position: 'absolute',
            bottom: borderBottom,
            transform: `translateY(50%)`,
          }}
        />
      </div>
      <motion.div
        style={{
          width: '50vw',
          height: '100vh',
          backgroundColor: 'black',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          position: 'relative',
        }}
      >
        <motion.div
          style={{
            color: 'white',
            width: '60%',
            position: 'absolute',
            bottom: textBottom,
            fontSize: '1.5vw',
            lineeight: '2.2vh',
            fontFamily: SECONDARY_FONT,
            transform: `translateY(50%)`,
          }}
        >
          We are an Australian-owned organization empowering local businesses
          with the best global talent. Our onshore presence, paired with the
          cost efficiency of offshore resources, sets us apart.
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Intro;
