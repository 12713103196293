import React, { useEffect, useState } from 'react';
import Home from './components/Home/Home';
import Loading from './components/Loading';
import { ReactLenis } from 'lenis/react';
import HowWeWork from './components/HowWeWork/HowWeWork';
import TagLine from './components/TagLine/TagLine';
import WhatDoWeDo from './components/WhatDoWeDo/WhatDoWeDo';
import Intro from './components/Intro/Intro';
import HorizontalScroll from './components/HorizontalScroll/HorizontalScroll';
import { motion } from 'framer-motion';
import ContactUs from './components/ContactUS/ContactUs';
import BackToTop from './components/BackToTop/BackToTop';
import IntroMobile from './components/Intro/IntroMobile';
import TagLineMobile from './components/TagLine/TagLineMobile';
import HorizontalScrollMobile from './components/HorizontalScroll/HorizontalScrollMobile';
import HowWeWorkMobile from './components/HowWeWork/HowWeWorkMobile';
import HomeMobile from './components/Home/HomeMobile';
import WhatDoWeDoMobile from './components/WhatDoWeDo/WhatDoWeDoMobile';
import BackToTopMobile from './components/BackToTop/BackToTopMobile';
import ContactUsMobile from './components/ContactUS/ContactUsMobile';

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuOpen]);

  const useScreenWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return width;
  };

  const screenWidth = useScreenWidth();
  const isMobile = screenWidth <= 700;

  if (isMobile) {
    return (
      <ReactLenis root>
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: isMenuOpen ? 0.5 : 1 }}
          transition={{ duration: 2 }}
          style={isMenuOpen ? { position: 'fixed', width: '100%' } : {}}
        >
          <Loading />
          <HomeMobile toggleMenu={() => setIsMenuOpen(true)} />
          <IntroMobile />
          <HowWeWorkMobile />
          <TagLineMobile />
          <HorizontalScrollMobile />
          <WhatDoWeDoMobile />
          <BackToTopMobile />
        </motion.div>
        <motion.div
          initial={{ y: '-100vh' }}
          animate={{ y: isMenuOpen ? 0 : '-100vh' }}
          transition={{ duration: 1, ease: 'easeInOut' }}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 1)',
            zIndex: 1000,
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ContactUsMobile closeMenu={() => setIsMenuOpen(false)} />
        </motion.div>
      </ReactLenis>
    );
  }

  return (
    <ReactLenis root>
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: isMenuOpen ? 0.5 : 1 }}
        transition={{ duration: 2 }}
        style={isMenuOpen ? { position: 'fixed', width: '100%' } : {}}
      >
        <Loading />
        <Home toggleMenu={() => setIsMenuOpen(true)} />
        <Intro />
        <HowWeWork />
        <TagLine />
        <HorizontalScroll />
        <WhatDoWeDo />
        <BackToTop />
      </motion.div>
      <motion.div
        initial={{ y: '-100vh' }}
        animate={{ y: isMenuOpen ? 0 : '-100vh' }}
        transition={{ duration: 1, ease: 'easeInOut' }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 1)',
          zIndex: 1000,
          color: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ContactUs closeMenu={() => setIsMenuOpen(false)} />
      </motion.div>
    </ReactLenis>
  );
};

export default App;
