/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { motion } from 'framer-motion';
import Globe from '../images/Globe.png';
import Icon from '../images/Icon.png';
import Icon1 from '../images/Icon1.png';
import Icon2 from '../images/Icon2.png';
import Icon3 from '../images/Icon3.png';
import Icon4 from '../images/Icon4.png';
import Icon5 from '../images/Icon5.png';
import Icon6 from '../images/Icon6.png';
import Icon7 from '../images/Icon7.png';
import Icon8 from '../images/Icon8.png';
import Icon9 from '../images/Icon9.png';
import Icon10 from '../images/Icon10.png';
import Icon11 from '../images/Icon11.png';
import Icon12 from '../images/Icon12.png';
import Icon13 from '../images/Icon13.png';
import Icon14 from '../images/Icon14.png';
import Icon15 from '../images/Icon15.png';
import Icon16 from '../images/Icon16.png';
import Icon17 from '../images/Icon17.png';
import Icon18 from '../images/Icon18.png';
import Icon19 from '../images/Icon19.png';
import Icon20 from '../images/Icon20.png';
import Icon21 from '../images/Icon21.png';
import Icon22 from '../images/Icon22.png';
import Icon23 from '../images/Icon23.png';
import Icon24 from '../images/Icon24.png';
import Icon25 from '../images/Icon25.png';

const getRandomIndices = () => [
  1, 5, 8, 10, 13, 15, 17, 19, 21, 25, 28, 30, 34, 38,
];

const images = [
  Icon2,
  Icon4,
  Icon6,
  Icon7,
  Icon9,
  Icon11,
  Icon12,
  Icon14,
  Icon15,
  Icon17,
  Icon19,
  Icon21,
  Icon23,
  Icon25,
];

// Create a map of random indices to images
const createIndexToImageMap = (indices, images) => {
  const map = {};
  const shuffledImages = [...images].sort(() => 0.5 - Math.random()); // Shuffle images

  indices.forEach((index, i) => {
    map[index] = shuffledImages[i % shuffledImages.length]; // Map index to an image
  });

  return map;
};

// Function to generate a random size percentage
const getRandomSize = () => Math.floor(Math.random() * 40) + 40; // Between 40% and 100%

const HeroAnimation = () => {
  const totalBoxes = 40;
  const redBoxIndices = getRandomIndices();
  const indexToImage = createIndexToImageMap(redBoxIndices, images);

  // Function to generate a random delay
  const getRandomDelay = () => Math.random() * 2; // Random delay between 0 and 2 seconds

  return (
    <motion.div
      style={{
        width: '95%',
        height: '100%',
        position: 'relative',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'black',
          position: 'absolute',
          opacity: 0.6,
          zIndex: 3,
        }}
      ></div>
      <motion.img
        src={Globe}
        alt="Description of image"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 0,
        }}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 1,
          delay: 2.5,
        }}
      >
        <motion.div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%', // Adjust this value as needed
            height: '80%', // Adjust this value as needed
            display: 'grid',
            gridTemplateColumns: 'repeat(8, 1fr)', // Number of columns
            gridTemplateRows: 'repeat(5, 1fr)', // Number of rows
            gap: '10px', // Adjust gap between squares
            zIndex: 1,
          }}
          variants={{
            animate: {
              transition: {
                staggerChildren: 0.1, // Stagger children by 0.1 seconds
                delayChildren: 0.5, // Delay the start of the animation
              },
            },
          }}
          initial="animate"
          animate="animate"
        >
          {Array.from({ length: totalBoxes }).map((_, index) => {
            const image = indexToImage[index];
            const size = getRandomSize(); // Random size percentage
            const delay = getRandomDelay(); // Random delay for each box

            return (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: [0, 1, 0], scale: [0, 1, 0] }}
                transition={{
                  duration: 2,
                  ease: 'easeInOut',
                  delay, // Apply the random delay
                  repeat: Infinity, // Repeat the animation indefinitely
                  repeatType: 'loop', // Loop the animation
                  repeatDelay: 1, // Delay between repetitions
                }}
                style={{
                  backgroundImage: image ? `url(${image})` : 'none',
                  backgroundSize: `${size}%`, // Random size for the image
                  backgroundRepeat: 'no-repeat', // Prevent image repetition
                  backgroundPosition: 'center', // Center the image in the box
                  width: '100%',
                  height: '100%',
                  border: 'none', // Ensure no border
                  boxSizing: 'border-box', // Include padding and border in the element's total width and height
                }}
              ></motion.div>
            );
          })}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default HeroAnimation;
