import React, { useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../lib/Colors';
import { SECONDARY_FONT } from '../../lib/Fonts';

const IntroMobile = () => {
  const container = useRef(null);

  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start end', 'end start'],
  });

  const opacityBorder = useTransform(scrollYProgress, [0.25, 0.5], [0, 1]);
  const opacitytText = useTransform(scrollYProgress, [0.55, 0.6], [0, 1]);

  return (
    <div>
      <motion.div
        ref={container}
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          backgroundColor: 'black',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: '70vw',
            height: '100vh',
            backgroundColor: 'black',
            fontSize: '10vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontFamily: 'Fjalla One',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ color: SECONDARY_COLOR }}>AU</span>
              <span>STRALIAN</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ color: SECONDARY_COLOR }}>RE</span>
              <span>MOTE</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <span style={{ color: SECONDARY_COLOR }}>TE</span>
              <span>AMS</span>
            </div>
          </div>
          <motion.div
            style={{
              height: '55vw',
              width: '100%',
              borderLeft: `5px solid ${PRIMARY_COLOR}`,
              position: 'absolute',
              opacity: opacityBorder,
            }}
          />
        </div>
        <div
          style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: 'black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <motion.div
            style={{
              color: 'white',
              width: '80%',
              position: 'absolute',
              fontSize: '5vw',
              lineHeight: '3.5vh',
              fontFamily: SECONDARY_FONT,
              textAlign: 'center', // Ensure text is centered
              opacity: opacitytText,
            }}
          >
            We are an Australian-owned organization empowering local businesses
            with the best global talent. Our onshore presence, paired with the
            cost efficiency of offshore resources, sets us apart.
          </motion.div>
        </div>
      </motion.div>
      <div
        style={{
          width: '100vw',
          height: '30vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'black',
        }}
      ></div>
    </div>
  );
};

export default IntroMobile;
