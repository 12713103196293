import { useScroll, useTransform, motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { PRIMARY_COLOR } from '../../lib/Colors';
import { SECONDARY_FONT } from '../../lib/Fonts';

const HowWeWork = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '400vh',
        fontFamily: 'Fjalla One',
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <div
        style={{
          position: 'sticky',
          width: '50vw',
          height: '100vh',
          top: 0,
          backgroundColor: 'black',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            fontSize: '8vw',
            color: 'white',
            margin: '0vw 5vw',
            padding: '0vw 2vw',
            borderLeft: `5px solid ${PRIMARY_COLOR}`,
          }}
        >
          WHY AURETE?
        </div>
      </div>
      <div style={{ width: '50vw', height: '400vh', backgroundColor: 'black' }}>
        <div
          style={{
            width: '50vw',
            height: '100vh',
            backgroundColor: 'black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              fontSize: '4vw',
              color: '#63d6aa',
              marginRight: '5vw',
              textTransform: 'uppercase',
            }}
          >
            Global Teams
          </div>
          <div
            style={{
              fontSize: '1.5vw',
              color: 'white ',
              marginRight: '5vw',
              fontFamily: SECONDARY_FONT,
            }}
          >
            We leverage a network of global talent, allowing us to assemble
            diverse teams that offer unique perspectives and expertise, ensuring
            the best solutions for your business.
          </div>
        </div>
        <div
          style={{
            width: '50vw',
            height: '100vh',
            backgroundColor: 'black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              fontSize: '4vw',
              color: '#63d6aa',
              marginRight: '5vw',
              textTransform: 'uppercase',
            }}
          >
            Client-Specific Hiring
          </div>
          <div
            style={{
              fontSize: '1.5vw',
              color: 'white ',
              marginRight: '5vw',
              fontFamily: SECONDARY_FONT,
            }}
          >
            Our recruitment process is tailored to match the specific needs of
            each client, ensuring that we provide the right skills and
            experience for every project.
          </div>
        </div>
        <div
          style={{
            width: '50vw',
            height: '100vh',
            backgroundColor: 'black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              fontSize: '4vw',
              color: '#63d6aa',
              marginRight: '5vw',
              textTransform: 'uppercase',
            }}
          >
            Verified Resources
          </div>
          <div
            style={{
              fontSize: '1.5vw',
              color: 'white ',
              marginRight: '5vw',
              fontFamily: SECONDARY_FONT,
            }}
          >
            We maintain stringent compliance and verification processes for all
            our resources, guaranteeing reliability and adherence to industry
            standards.
          </div>
        </div>
        <div
          style={{
            width: '50vw',
            height: '100vh',
            backgroundColor: 'black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              fontSize: '4vw',
              color: '#63d6aa',
              marginRight: '5vw',
              textTransform: 'uppercase',
            }}
          >
            Fully Equipped Resources
          </div>
          <div
            style={{
              fontSize: '1.5vw',
              color: 'white ',
              marginRight: '5vw',
              fontFamily: SECONDARY_FONT,
            }}
          >
            All team members are provided with state-of-the-art tools and
            resources by Aurete, ensuring they are fully prepared to deliver
            high-quality results.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWeWork;
