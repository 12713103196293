import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { PRIMARY_COLOR } from '../../lib/Colors';
import { PRIMARY_FONT, SECONDARY_FONT } from '../../lib/Fonts';
import emailjs from '@emailjs/browser';

const ContactUs = ({ closeMenu }) => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    if (loading) {
      return null;
    }

    setLoading(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        'template_zyaprd2',
        form.current,
        {
          publicKey: process.env.REACT_APP_EMAIL_PUBLIC_KEY,
          blockHeadless: true,
          limitRate: {
            id: 'app',
            throttle: 10000,
          },
        },
      )
      .then(
        () => {
          setShowThankYou(true);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          console.error('FAILED...', error.text);
          console.error('FAILED...', error);
        },
      );
  };

  if (showThankYou) {
    return (
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 3 }}
        exit={{ opacity: 0, y: -50 }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          fontFamily: 'Fjalla One',
          padding: '20px',
          boxSizing: 'border-box',
          transition: 'all 0.5s ease',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            color: 'white',
            fontFamily: SECONDARY_FONT,
          }}
        >
          <h1
            style={{
              fontSize: '2rem',
              marginBottom: '20px',
              fontFamily: PRIMARY_FONT,
            }}
          >
            Thank You for Getting in Touch!
          </h1>
          <p style={{ fontSize: '1.2rem' }}>
            We appreciate you contacting us. One of our colleagues will get back
            in touch with you soon!
          </p>
          <motion.button
            onClick={closeMenu}
            whileTap={{ scale: 0.9 }}
            style={{
              marginTop: '30px',
              padding: '15px 30px',
              borderRadius: '5px',
              border: 'none',
              backgroundColor: PRIMARY_COLOR,
              color: 'black',
              cursor: 'pointer',
              fontSize: '16px',
              fontFamily: SECONDARY_FONT,
            }}
          >
            Close
          </motion.button>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontFamily: 'Fjalla One',
        padding: '20px',
        boxSizing: 'border-box',
        transition: 'all 0.5s ease',
      }}
    >
      <motion.button
        onClick={closeMenu}
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.8 }}
        style={{
          position: 'fixed',
          top: '20px',
          right: '30px',
          background: 'transparent',
          border: 'none',
          color: 'white',
          fontSize: '5vw',
          cursor: 'pointer',
          zIndex: 1100,
        }}
      >
        &times;
      </motion.button>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '800px',
          margin: '0 auto',
          position: 'relative',
          padding: '20px',
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{
            flex: 1,
            padding: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
            textAlign: 'center',
            fontFamily: SECONDARY_FONT,
          }}
        >
          <h1 style={{ fontSize: '2rem', lineHeight: '1.2' }}>
            Together, We Achieve More
          </h1>
        </div>
        <motion.div
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
          style={{
            flex: 1,
            padding: '20px',
            backgroundColor: '#111',
            borderRadius: '10px',
            boxSizing: 'border-box',
          }}
        >
          <form ref={form} onSubmit={sendEmail}>
            <div style={{ marginBottom: '20px' }}>
              <input
                type="text"
                name="user_name"
                placeholder="Name"
                required
                style={{
                  width: '100%',
                  padding: '10px 15px',
                  borderRadius: '5px',
                  border: '1px solid #444',
                  backgroundColor: '#222',
                  color: 'white',
                  fontSize: '16px',
                  marginBottom: '10px',
                  boxSizing: 'border-box',
                  fontFamily: SECONDARY_FONT,
                }}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <input
                type="text"
                name="user_phone"
                placeholder="Phone"
                required
                style={{
                  width: '100%',
                  padding: '10px 15px',
                  borderRadius: '5px',
                  border: '1px solid #444',
                  backgroundColor: '#222',
                  color: 'white',
                  fontSize: '16px',
                  marginBottom: '10px',
                  boxSizing: 'border-box',
                  fontFamily: SECONDARY_FONT,
                }}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <input
                type="email"
                name="user_email"
                placeholder="Email Address"
                required
                style={{
                  width: '100%',
                  padding: '10px 15px',
                  borderRadius: '5px',
                  border: '1px solid #444',
                  backgroundColor: '#222',
                  color: 'white',
                  fontSize: '16px',
                  marginBottom: '10px',
                  boxSizing: 'border-box',
                  fontFamily: SECONDARY_FONT,
                }}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <textarea
                name="message"
                placeholder="Tell us how we can help"
                rows="4"
                style={{
                  width: '100%',
                  padding: '10px 15px',
                  borderRadius: '5px',
                  border: '1px solid #444',
                  backgroundColor: '#222',
                  color: 'white',
                  fontSize: '16px',
                  marginBottom: '10px',
                  boxSizing: 'border-box',
                  fontFamily: SECONDARY_FONT,
                }}
              />
            </div>
            <motion.button
              type="submit"
              whileTap={{ scale: 0.9 }}
              style={{
                width: '100%',
                padding: '15px',
                borderRadius: '5px',
                border: 'none',
                backgroundColor: loading ? 'grey' : PRIMARY_COLOR,
                color: '#000',
                cursor: 'pointer',
                fontSize: '16px',
                fontFamily: SECONDARY_FONT,
              }}
            >
              Get in Touch
            </motion.button>
          </form>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ContactUs;
