import { useScroll, useTransform, motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../lib/Colors';
import { PRIMARY_FONT, SECONDARY_FONT } from '../../lib/Fonts';

const ContentBox = ({ number, title, description }) => (
  <div
    style={{
      width: '28vw',
      height: '100%',
      border: `3px solid ${PRIMARY_COLOR}`,
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
    }}
  >
    <div
      style={{
        fontSize: '8vw',
        color: PRIMARY_COLOR,
        fontFamily: 'Playfair Display, serif',
        marginBottom: 'auto',
        flex: 1,
      }}
    >
      {number}
    </div>
    <div
      style={{
        alignSelf: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
        gap: '1vw',
        flex: 2,
      }}
    >
      <div
        style={{
          fontSize: '3vw',
          color: SECONDARY_COLOR,
          fontFamily: 'Fjalla One',
          textTransform: 'uppercase',
          flex: 1,
        }}
      >
        {title}
      </div>
      <div
        style={{
          fontSize: '1.5vw',
          color: 'white',
          fontFamily: SECONDARY_FONT,
          flex: 1,
        }}
      >
        {description}
      </div>
    </div>
  </div>
);

const HorizontalScroll = () => {
  const anotherContainer = useRef(null);
  const { scrollYProgress } = useScroll({
    target: anotherContainer,
    offset: ['start start', 'end end'],
  });

  const slide = useTransform(scrollYProgress, [0, 1], ['90vw', '-10vw']);

  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      console.log('Scroll Progress:', latest);
    });
  }, [scrollYProgress]);

  const contentData = [
    {
      number: 1,
      title: 'Start ups',
      description:
        'Starting a business and securing funding is hard. Aurete supports you from planning to launch and upgrades, turning your ideas into reality.',
    },
    {
      number: 2,
      title: 'Small medium businesses',
      description:
        'At Aurete, we believe in building long-term partnerships. To support eligible SMBs, we offer a 45-day credit cycle to start with us, requiring no upfront cost.',
    },
    {
      number: 3,
      title: 'Enterprise',
      description:
        'Aurete can seamlessly white label as part of your team to ensure timely delivery and provide a full onshore team with security clearance if needed.',
    },
  ];

  return (
    <div
      ref={anotherContainer}
      style={{
        width: '100vw',
        height: '300vh',
        fontFamily: SECONDARY_FONT,
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <motion.div
        style={{
          position: 'sticky',
          width: '100vw',
          height: '100vh',
          top: 0,
          backgroundColor: 'black',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <motion.div
          style={{
            width: '100%',
            height: '65%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            position: 'absolute',
            left: slide,
          }}
        >
          {contentData.map((content, index) => (
            <ContentBox
              key={index}
              number={content.number}
              title={content.title}
              description={content.description}
            />
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default HorizontalScroll;
