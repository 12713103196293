/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import { motion } from 'framer-motion'; // Only for other possible animations if needed
import Globe from '../images/Globe.png';

const HeroAnimation = () => {
  return (
    <>
      {/* Define keyframes in a style tag */}
      <style>
        {`
          @keyframes slide {
            0% {
              object-position: 0% 50%;
            }
            100% {
              object-position: 100% 50%;
            }
          }

          .animated-image {
            animation: slide 8s linear 3s infinite alternate;
          }
        `}
      </style>

      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'black',
            position: 'absolute',
            opacity: 0.6,
            zIndex: 3,
          }}
        ></div>
        <img
          src={Globe}
          alt="Description of image"
          className="animated-image"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            objectPosition: 'left',
            top: 0,
            left: 0,
            zIndex: 0,
          }}
        />
      </div>
    </>
  );
};

export default HeroAnimation;
