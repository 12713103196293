import { useScroll, useTransform, motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import main from '../../images/main.png';
import { SECONDARY_COLOR } from '../../lib/Colors';

const WhatDoWeDo = () => {
  const anotherContainer = useRef(null);
  const { scrollYProgress } = useScroll({
    target: anotherContainer,
    offset: ['start start', 'end end'],
  });

  useEffect(() => {
    console.log(scrollYProgress.current);
  }, [scrollYProgress]);

  const scale1 = useTransform(scrollYProgress, [0, 1], [1, 3]);
  const opacity1 = useTransform(scrollYProgress, [0, 1], [0, 1]);

  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      console.log('Scroll Progress:', latest);
    });
  }, [scrollYProgress]);

  return (
    <div
      ref={anotherContainer}
      style={{
        width: '100vw',
        height: '200vh',
        fontFamily: 'Fjalla One',
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <motion.div
        style={{
          position: 'sticky',
          width: '100vw',
          height: '100vh',
          top: 0,
          backgroundColor: 'black',
          overflow: 'hidden',
        }}
      >
        <motion.img
          src={main}
          alt="Fixed Size Image"
          style={{
            width: '100vw',
            height: 'auto',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', // Center the image
            zIndex: 1, // Ensure the image is on top
            opacity: opacity1,
          }}
        />
        <motion.div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            scale: scale1,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                fontSize: '8vw',
                color: 'white',
                padding: '3vw',
                textTransform: 'uppercase',
              }}
            >
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
              >
                <span style={{ color: SECONDARY_COLOR }}>Onshore</span>
                <span>Expertise</span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <div
              style={{
                fontSize: '8vw',
                color: 'white',
                padding: '3vw',
                textTransform: 'uppercase',
              }}
            >
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
              >
                <span style={{ color: SECONDARY_COLOR }}>Offshore</span>
                <span>Efficiency</span>
              </div>{' '}
            </div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default WhatDoWeDo;
